<template>
  <div class="h-screen relative">
    <div class="h-full">
      <navbar-white
        boxShadow="shadow-[0_4px_4px_-2px_rgba(0,0,0,0.25)]"
        title="Detail Transaksi"
        srcPictureLeft="backIconBlack.svg"
        :onClickBack="goBack"
      />
      <div class="mt-4 mx-[30px]">
        <div class="flex flex-col">
          <img
            alt="checkListGreen"
            class="mx-auto w-11"
            :src="require(`@/assets/icon/waiting_approve.svg`)"
          />
          <p class="mx-auto mt-3 font-semibold">Disetujui</p>
          <div class="mx-auto mt-3 mb-6 text-sm">
            <p>Transakasi Anda Telah Disetujui oleh perusahaan.</p>
            <p class="text-center">Dana akan ditransfer dalam waktu 1x24jam.</p>

          </div>
          

        </div>
        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nomor Referensi</div>
          <div class="text-right">{{ item.requestTransactionId }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Tanggal Transaksi</div>
          <div>
            {{ moment(item.createdAt).locale("id").format("DD-MM-YYYY") }}
          </div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Waktu Transaksi</div>
          <div>{{ moment(item.createdAt).locale("id").format("LTS") }} WIB</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">No. Rekening</div>
          <div>{{ item.bankAccountNumber }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nama Penerima</div>
          <div>{{ item.bankAccountName }}</div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Bank Tujuan</div>
          <div>{{ item.bankAccount }}</div>
        </div>

        <hr class="mb-4" />

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nominal</div>
          <div>{{ convertRupiah(item.amount) }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Biaya Admin</div>
          <div>- {{ convertRupiah(item.fee) }}</div>
        </div>

        <div class="flex flex-row justify-between">
          <div class="font-semibold">Total</div>
          <div class="font-semibold">
            {{ convertRupiah(item.amountTransfer) }}
          </div>
        </div>
      </div>
    </div>
    <button-bottom
      :Click="goBack"
      data-bs-target="#confirmModal"
      data-bs-toggle="modal"
      >Kembali Ke Menu</button-bottom
    >
  </div>
</template>
<script>
import { formatPrice } from "@/utils/helper.js";
import moment from "moment";
import ButtonBottom from "@/components/ButtonBottom.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
export default {
  name: "TransactionApproval",
  components: {
    ButtonBottom,
    NavbarWhite,
    ButtonPrimary,
  },
  data() {
    return {
      moment: moment,
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-2);
    },
    convertRupiah(money) {
      return formatPrice(money);
    },
  },
};
</script>x