import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { removeNotif } from '../utils/cookies'
export const setInquiry = (noRek, nama, bank, amount, fee, total,) => {


    localStorage.setItem("hk_noRek", CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(noRek), process.env.VUE_APP_SECRET_KEY))
    localStorage.setItem("hk_name_transaction", CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(nama), process.env.VUE_APP_SECRET_KEY))
    localStorage.setItem("hk_bank", CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(bank), process.env.VUE_APP_SECRET_KEY))
    localStorage.setItem("hk_amount", CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(amount), process.env.VUE_APP_SECRET_KEY))
    localStorage.setItem("hk_fee", CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(fee), process.env.VUE_APP_SECRET_KEY))
    localStorage.setItem("hk_total", CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(total), process.env.VUE_APP_SECRET_KEY))

}

export const setSize = (size) => {
    localStorage.setItem("hk_size", CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(size), process.env.VUE_APP_SECRET_KEY))
}
export const getSize = () => {
    const crypt = localStorage.getItem("hk_size") || ""
    const bytes = CryptoJS.AES.decrypt(
        crypt,
        process.env.VUE_APP_SECRET_KEY
    );

    const size = bytes.toString(CryptoJS.enc.Utf8);
    return Number(size)
}

export const setTransId = (id) => {
    localStorage.setItem("hk_trans_id", CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(id), process.env.VUE_APP_SECRET_KEY))
}
export const setVerified = () => {
    localStorage.setItem("hk_verifCode", "yes")
}
export const getVerified = () => {
    return localStorage.getItem("hk_verifCode") || ""
}
export const getTransId = () => {
    const crypt = localStorage.getItem('hk_trans_id')

    const bytes = CryptoJS.AES.decrypt(
        crypt,
        process.env.VUE_APP_SECRET_KEY
    );

    const transId = bytes.toString(CryptoJS.enc.Utf8);
    return transId
}
export const getNoRek = () => {
    const crypt = localStorage.getItem('hk_noRek')

    const bytes = CryptoJS.AES.decrypt(
        crypt,
        process.env.VUE_APP_SECRET_KEY
    );

    const noRek = bytes.toString(CryptoJS.enc.Utf8);
    return noRek
}
export const getNameTransaction = () => {
    const crypt = localStorage.getItem('hk_name_transaction')

    const bytes = CryptoJS.AES.decrypt(
        crypt,
        process.env.VUE_APP_SECRET_KEY
    );

    const name = bytes.toString(CryptoJS.enc.Utf8);
    return name
}
export const getBank = () => {
    const crypt = localStorage.getItem('hk_bank')

    const bytes = CryptoJS.AES.decrypt(
        crypt,
        process.env.VUE_APP_SECRET_KEY
    );

    const bank = bytes.toString(CryptoJS.enc.Utf8);
    return bank
}
export const getAmount = () => {
    const crypt = localStorage.getItem('hk_amount')

    const bytes = CryptoJS.AES.decrypt(
        crypt,
        process.env.VUE_APP_SECRET_KEY
    );

    const amount = bytes.toString(CryptoJS.enc.Utf8);
    return Number(amount)
}
export const getFee = () => {
    const crypt = localStorage.getItem('hk_fee')

    const bytes = CryptoJS.AES.decrypt(
        crypt,
        process.env.VUE_APP_SECRET_KEY
    );

    const fee = bytes.toString(CryptoJS.enc.Utf8);
    return Number(fee)
}
export const getTotal = () => {
    const crypt = localStorage.getItem('hk_total')

    const bytes = CryptoJS.AES.decrypt(
        crypt,
        process.env.VUE_APP_SECRET_KEY
    );

    const total = bytes.toString(CryptoJS.enc.Utf8);
    return Number(total)
}

export const removeTransSummary = () => {
    localStorage.removeItem("hk_noRek")
    localStorage.removeItem("hk_name_transaction")
    localStorage.removeItem("hk_bank")
    localStorage.removeItem("hk_amount")
    localStorage.removeItem("hk_fee")
    localStorage.removeItem("hk_total")
};

//Auth Localstorage

export const setAuthentication = (token, name, company, email,lastLogin) => {
    localStorage.setItem('hk_access_token', token)
    localStorage.setItem('hk_acc_name', CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(name), process.env.VUE_APP_SECRET_KEY))
    localStorage.setItem('hk_company_name', CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(company), process.env.VUE_APP_SECRET_KEY))
    localStorage.setItem('hk_email', CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(email), process.env.VUE_APP_SECRET_KEY))
    localStorage.setItem('hk_lastlogin', CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(lastLogin), process.env.VUE_APP_SECRET_KEY))

}

export const getToken = () => localStorage.getItem('hk_access_token') || ''
export const getName = () => {
    const getName = localStorage.getItem('hk_acc_name') || ''
    let bytes = CryptoJS.AES.decrypt(
        getName,
        process.env.VUE_APP_SECRET_KEY
    );
    const name = bytes.toString(CryptoJS.enc.Utf8)
    return name
}
export const getCompanyName = () => {
    const getCompany = localStorage.getItem('hk_company_name') || ''
    let bytes = CryptoJS.AES.decrypt(
        getCompany,
        process.env.VUE_APP_SECRET_KEY
    );
    const company = bytes.toString(CryptoJS.enc.Utf8)
    return company
}
export const getEmail = () => {
    const getMail = localStorage.getItem('hk_email' || '')
    let bytes = CryptoJS.AES.decrypt(
        getMail,
        process.env.VUE_APP_SECRET_KEY
    );
    const email = bytes.toString(CryptoJS.enc.Utf8)
    return email
}
export const getLastlogin = () => {
    const getLastlogiuser = localStorage.getItem('hk_lastlogin' || '')
    let bytes = CryptoJS.AES.decrypt(
        getLastlogiuser,
        process.env.VUE_APP_SECRET_KEY
    );
    const lastLogin = bytes.toString(CryptoJS.enc.Utf8)
    return lastLogin
}
export const removeAuth = () => {
    localStorage.removeItem('hk_access_token')
    localStorage.removeItem('hk_acc_name')
    localStorage.removeItem('hk_company_name')
    localStorage.removeItem('hk_email')
    localStorage.removeItem('hk_old_pin')
    localStorage.removeItem('hk_lastlogin')
    Cookies.remove('hk_marker')
    removeNotif()
}


//PIN Localstorage
export const setOldPin = (pin) => {
    const oldPin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(pin), process.env.VUE_APP_SECRET_KEY);
    localStorage.setItem('hk_old_pin', oldPin)

}

export const setStagingPin = (pin) => {
    const stagingPin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(pin), process.env.VUE_APP_SECRET_KEY);
    localStorage.setItem('hk_staging_pin', stagingPin)
}

export const getStagingPin = () => {
    const pin = localStorage.getItem('hk_staging_pin') || ''
    let bytes = CryptoJS.AES.decrypt(
        pin,
        process.env.VUE_APP_SECRET_KEY
    );
    let stagingPin = bytes.toString(CryptoJS.enc.Utf8);
    return stagingPin
}
export const getOldPin = () => {
    const pin = localStorage.getItem('hk_old_pin') || ''
    let bytes = CryptoJS.AES.decrypt(
        pin,
        process.env.VUE_APP_SECRET_KEY
    );
    let oldPin = bytes.toString(CryptoJS.enc.Utf8);
    return oldPin
}

export const getOldPin_Undecrypt = () => {
    return localStorage.getItem("hk_old_pin") || ""
}

export const removeStagingPin = () => {
    localStorage.removeItem('hk_staging_pin')
}