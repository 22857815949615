<template>
  <div class="relative h-screen">
    <div class="h-full">
      <nav-bar title="Login" />

      <img
        class="mx-auto my-10 w-28 h-28"
        :src="require(`@/assets/icon/hakkuLogoOnly.svg`)"
        alt="logo"
      />

      <form>
        <div class="mx-[30px]">
          <label
            class="block text-black-form text-lg font-semibold mb-2"
            for="email"
          >
            Masukkan Email dan Password
          </label>

          <input
            v-model="email"
            class="
              shadow
              appearance-none
              border-none
              outline outline-1
              rounded
              w-full
              py-2
              px-3
              text-grey-darker
              h-11
            "
            :class="{
              'mb-4': !errorMsg.email,
              'outline-grey-input': !errorMsg.email,
              'outline-red-star': errorMsg.email,
            }"
            id="email"
            type="email"
            placeholder="Email"
            @focus="focus"
            
          />
          <!-- <p v-if="isError" class="text-red-star mx-[30px] mb-4">
            {{ errorCause }}
          </p> -->
          <p v-if="errorMsg.email" class="text-red-star mb-2">
            {{ errorMsg.email }}
          </p>
          <div class="relative">
            <input
              
              v-model="password"
              v-if="showPassword"
              class="
                shadow
                appearance-none
                border-none
                outline outline-1
                rounded
                w-full
                py-2
                px-3
                text-grey-darker
                h-11
              "
              :class="{
                'outline-grey-input': !errorMsg.password,
                'outline-red-star': errorMsg.password,
              }"
              id="email"
              type="text"
              placeholder="Password"
              @focus="focus"
              @keyup.enter="loginAction"
            />
            <input
              v-model="password"
              v-else
              class="
                shadow
                appearance-none
                border-none
                outline outline-1
                rounded
                w-full
                py-2
                px-3
                text-grey-darker
                h-11
              "
              :class="{
                'outline-grey-input': !errorMsg.password,
                'outline-red-star': errorMsg.password,
              }"
              id="password"
              type="password"
              placeholder="Password"
              @focus="focus"
              @keyup.enter="loginAction"
            />

            <div
              class="
                absolute
                inset-y-0
                right-2
                flex
                items-center
                text-base
                leading-5
                cursor-pointer
              "
            >
              <span class="bg-grey-darker"
                ><i
                  class="fa-lg fa-regular"
                  :class="{
                    'fa-eye-slash': !showPassword,
                    'fa-eye': showPassword,
                  }"
                  @click="toggleShow"
                ></i
              ></span>
            </div>
          </div>
        </div>
        <!-- <p v-if="isError" class="text-red-star mx-[30px] mb-4">
          {{ errorCause }}
        </p> -->
        <p v-if="errorMsg.password" class="text-red-star mx-[30px] mb-4">
          {{ errorMsg.password }}
        </p>
        <div class="flex justify-end mr-6 mt-9 mb-11">
          <a
            @click="toResetPass"
            class="
              inline-block
              align-baseline
              font-bold
              text-base text-nav-blue
              cursor-pointer
            "
          >
            Lupa Password?
          </a>
        </div>
        <div class="mx-[30px]">
          <button-primary :isLoading="isLoading" :onClick="loginAction"
            >Masuk</button-primary
          >
        </div>

        <p class="text-center mt-7">
          Perusahaan Anda belum terdaftar?
          <span class="text-nav-blue font-semibold cursor-pointer"
            ><a @click="toRegister">Daftar disini.</a></span
          >
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import { AuthControllers } from "@/controllers/AuthControllers.js";
import NavBar from "@/components/NavBar.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import { setModal, removeOnboarding } from "@/utils/cookies";

export default {
  name: "Login",
  data() {
    return {
      auth: new AuthControllers(false, false, ""),
      showPassword: false,
      title: "Masukkan Email dan Password",
      email: "",
      password: "",
      hk_modal: "yes",
      validate: {
        emptyEmail: false,
        emptyPassword: false,
        email: false,
        password: false,
      },
      errorMsg: {
        email: "",
        password: "",
      },
    };
  },
  components: {
    NavBar,
    ButtonPrimary,
  },
  computed: {
    isError() {
      return this.auth.error;
    },

    errorCause() {
      return this.auth.errorCause;
    },

    isLoading() {
      return this.auth.loading;
    },
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
    password(value) {
      this.password = value;
      this.validatePassword(value);
    },
  },
  methods: {
    async doLogin(email, password) {
      return this.auth.signIn(email, password);
    },
    async Login() {
      await this.doLogin(this.email, this.password);
      // const pin = getPin();
      // if (!this.isError) {
      //   if (!pin) {
      //     this.toAuth;
      //     this.$router.push("/setuppin");
      //     window.location.reload();
      //   } else {
      //     this.toAuth;
      //     window.location.reload();
      //   }
      // }
      if (!this.isError) {
        this.toAuth();
        // this.$router.push("/home");
        window.location.reload();
        this.isLoading = false;
        // this.$store.dispatch("pin/getPin");
      } else if (this.errorCause == "user not found") {
        this.errorMsg.email = "Email Belum Terdaftar";
        this.errorMsg.password = "Password salah. Silahkan coba lagi";
      } else if (this.errorCause == "Not a valid email") {
        this.errorMsg.email = "Email tidak boleh kosong";
        if (this.password == "") {
          this.errorMsg.password = "Password tidak boleh kosong";
        } else {
          this.errorMsg.password = "";
        }
      } else if (
        this.errorCause == "password invalid" ||
        this.errorCause == "Not a valid password"
      ) {
        if (this.password == "") {
          this.errorMsg.password = "Password tidak boleh kosong";
        } else {
          this.errorMsg.password = "Password salah. Silahkan coba lagi";
        }
      }
    },
    loginAction() {
      this.Login();
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errorMsg.email = "";
      } else {
        this.errorMsg.email = "Email tidak Valid";
      }
      if (email == "") {
        this.errorMsg.email = "Email tidak boleh Kosong";
      }
    },
    validatePassword(password) {
      if (/^(?=.*?[0-9])[a-zA-Z0-9!@#?\$%\^\&*\)\(+=._-]{8,}$/g.test(password)) {
        this.errorMsg.password = "";
      } else {
        this.errorMsg.password =
          "Password minimal 8 karakter kombinasi huruf dan angka";
      }
      if (password == "") {
        this.errorMsg.password = "Password tidak boleh Kosong";
      }
    },
    focus() {
      this.validate.empty = false;
    },

    toggleShow() {
      if (this.showPassword === false) {
        this.showPassword = true;
      } else {
        this.showPassword = false;
      }
    },

    toAuth() {
      setModal(this.hk_modal);
    },
    toRegister() {
      this.$router.push("/register");
    },
    toResetPass() {
      this.$router.push("/resetpass");
    },
    toHistory() {
      this.$router.push("/transaction-history");
    },
  },
  mounted() {},
};
</script>